//
// form-wizard.scss
//


.wizard{
    // steps

    >.steps {
        background-color: $gray-100;
        position: relative;
        display: block;
        width: 100%;
        >ul{
            >li {
                width: 25%;
            }
        }

        .number {
            display: inline-block;
            border-radius: 50%;
            background-color: rgba($white, 0.3);
            line-height: 30px;
            margin-right: 10px;
            width: 30px;
            text-align: center;
            font-size: 1.429em;
        }

        a, a:hover, a:active{
            display: block;
            width: auto;
            padding: 1em 1em;
            text-decoration: none;
            border-radius: 2px;
        }

        .disabled{
            a, a:hover, a:active{
                background: transparent;
                color: $dark;
                cursor: default;
                border: none;
            }
        }

        .current{
            a, a:hover, a:active{
                background: $cyan;
                color: $white;
                cursor: default;
            }
        }

        .done{
            a, a:hover, a:active{
                background: $gray-300;
                color: $dark;
            }
        }

        .error{
            a, a:hover, a:active{
                background: $danger;
                color: $white;
            }
        }
    }

    >.steps, >.actions{
        >ul{
            >li {
                float: left;
            }
        }
    }

    // content

    >.content {
        display: block;
        margin: 0.5em;
        min-height: 240px;
        overflow: hidden;
        position: relative;
        width: auto;
        padding: 20px;
        >.body {
            padding: 0;
            position: relative;
            width: 95%;
            height: 95%;
            margin: 0px auto;

            ul {
                list-style: disc !important;
                >li {
                    display: block;
                    line-height: 30px;
                }
            }

            >iframe {
                border: 0 none;
                width: 100%;
                height: 100%;
            }

            input {
                display: block;
                border-color: $input-border-color;

                &:focus {
                    border-color: $input-focus-border-color;
                }
                &[type="checkbox"] {
                    display: inline-block;
                }

                &.error {
                    background: $gray-300;
                    border: 1px solid $gray-300;
                    color: $danger;
                }
            }

            label {
                display: inline-block;
                margin-bottom: 0.5em;
                margin-top: 10px;
                &.error {
                    color: $danger;
                    display: inline-block;
                    margin-left: 1.5em;
                }
            }
        }
    }

    // actions

    >.actions {
        position: relative;
        display: block;
        text-align: right;
        width: 100%;
        padding: 0 20px;
        >ul {
            display: inline-block;
            text-align: right;
            >li {
                margin: 0 0.5em;
            }
        }

        a, a:hover, a:active{
            background: $cyan;
            color: $white;
            display: block;
            padding: 0.5em 1em;
            text-decoration: none;
            border-radius: 2px;
        }

        .disabled{
            a, a:hover, a:active{
                background: $gray-200;
                color: $dark;
            }
        }
    }

    // vertical wizard

    &.vertical{
        >.steps {
            display: inline;
            float: left;
            width: 30%;
            >ul{
                >li {
                    float: none;
                    width: 100%;
                }
            }
        }

        >.actions {
            display: inline;
            float: right;
            width: 95%;
            >ul{
                >li {
                    margin: 0 0 0 1em;
                }
            }
        }
    }  
}



/*
    Common 
*/

.wizard, .tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;
    a{
        outline: 0;
    }

    ul {
        list-style: none !important;
        padding: 0;
        margin: 0;

        >li {
            display: block;
            padding: 0;
        }
    }

    /* Accessibility */

    >.steps {
        .current-info {
            position: absolute;
            left: -999em;
        }
    }

    >.content{
        >.title {
            position: absolute;
            left: -999em;
        }
    }
}

@include media-breakpoint-down(sm) { 
    .wizard > .steps > ul > li,.wizard.vertical > .steps,.wizard.vertical > .content {
        width: 100%;
    }
}