// 
// dashboard.scss
//


// Dashboard charts
.dash-sales-chart {
    height: 165px;
}

.high-performing-product, .conversion-chart {
    height: 309px;
}

.dash-doughnut {
    height: 186px;
}

.dash-usa-map {
    height: 300px;
}

.datauses-area {
    height: 198px;
}