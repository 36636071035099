//
// layouts.scss
//

body {
    &.boxed-layout {
        .container-fluid {
            max-width: $boxed-layout-width;
        }
    }
}